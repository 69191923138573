import React from "react";
// let path =require('../create/index.html');

class TestScreen extends React.Component {
    
   render(){
      return (
        //  <iframe src={path}></iframe>   /* like this */
        <div>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.min.js"></script>
            <script src="canvas-class.js"></script>
            <script src="rule-classes.js"></script>
            <script src="classes.js"></script>
            <script src="sketch-game-flow.js"></script>
        </div>
      );
   }
}
export default TestScreen;