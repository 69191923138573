import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Heading = styled.h1`
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;


const CenteredList = styled.ul`
  padding: 0;
  text-align: center;
`;

const ListItem = styled.li`
  text-align: left; /* Align text to the left within each list item */
`;

const StyledLink = styled(Link)`
  color: var(--secondary-color);
  &:hover {
    text-decoration: underline;
  }
`;


const AboutUs = () => {
  return (
    <Container>
      <Heading>About Us</Heading>
      <Paragraph>
      Welcome to our website. We are passionate about creating stimulating content and powerful tools for education.
      </Paragraph>
      <Paragraph>
      Our mission is to unlock the creativity of our users. By so doing, they can make learning fun and more effective. 
      We enable children of all ages to make their own video games. We call them eyedeas. These can be purely for enjoyment, or they can have a serious teaching purpose. In every case, they teach powerful thinking skills.

      </Paragraph>
      <Paragraph>
      <CenteredList>
        <ListItem>They teach the power of programming using an entirely new way to program computers, a way that everyone can do.</ListItem>
        <ListItem>They unleash the ability of computers to explain complex ideas.</ListItem>
        <ListItem>They illustrate the power of visual thinking.</ListItem>
        <ListItem>They teach sequential problem solving: breaking problems into pieces.</ListItem>
        <ListItem>They show that the best learning is when children learn together, collaboratively.</ListItem>
      </CenteredList>
      
      We provide a marketplace where the games children create can be distributed to others, either freely or for a fee.
      </Paragraph>
      <Paragraph>
        Our goal is to improve education in the 21st century.
      </Paragraph>
      <Paragraph>
        If you have any questions or suggestions, please feel free to <StyledLink to="/contactus">contact us</StyledLink>.
      </Paragraph>
    </Container>
  );
};

export default AboutUs;
