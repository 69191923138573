import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';  // Import getAuth and onAuthStateChanged
import 'firebase/auth';
import AuthStack from './AuthStack'; // Update this with your web-specific auth stack
import AppStack from './AppStack';  // Update this with your web-specific app stack
import { AuthContext } from './AuthProvider';
import {auth} from '../firebase-config';

// sends a logout request to the server
async function logout() {
    try {
      const response = await fetch('logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Adjust the content type if necessary
        }
      });
  
      if (!response.ok) {
        throw new Error('Could not log out');
      }
  
      console.log('Logout successful');
    } catch (error) {
      console.error(error.message);
    }
  }

const AppRoutes = () => {
    const { user, setUser } = useContext(AuthContext);
    const [initializing, setInitializing] = useState(true);
// log out -> can't log back in doesn't send you back to auth login page, does not let you log in again
    const onAuthStateChangedUser = async (user) => {
        if (user) {  //
            const token = await user.getIdToken();
            checkSession(token); //
        setUser(user);
        if (initializing) setInitializing(false);
        } //
        else {
            console.log("no user");
            logout(); 
            // redirect to login page
            setUser(null);
            if (initializing) setInitializing(false);
            return <Navigate to="/auth/login" />; 
        } //
    }

    useEffect(() => {
        const subscriber = onAuthStateChanged(auth, onAuthStateChangedUser);
        return subscriber; // Unsubscribe on unmount
    }, []);
    
    const checkSession = async (token) => {
    try {
        const response = await fetch('check-session', { 
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token here
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token })
        });

        // evaluate response status and act accordingly
        if (response.ok) {
            const data = await response.json();
            console.log("session continuation");
            // Handle successful session continuation here
        } else {
            // Handle session check failure here
            console.log("session check failed", user);
        }
        // Handle successful session continuation here
    } catch (error) {
        console.error('Session check failed', error);
        // Handle session check failure here
    }
    };

    if (initializing) return null;

    return (
        <Router>
            <Routes>
                {/* {user ? (
                    <Route path="/*" element={<AppStack />} />
                ) : (
                    <Route path="/auth/*" element={<AuthStack />} />
                )}
                <Route path="*" element={<Navigate to={user ? "/" : "/auth"} />} /> */}
                <Route path="/*" element={<AppStack />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
