import React, { useState } from 'react';
import styled from 'styled-components';

// Popup styled component
const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000; // Ensure it's above other content
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  margin-right: 20px;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 32px;
  flex-grow: 2;
  color: white;
  gap: 10px;
  border: none;
`;

const Popup = ({ link, onClose }) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Link copied to clipboard!');
      onClose(); // Close the popup after copying
    });
  };

  return (
    <PopupContainer>
      <p>Copy this link to share your game:</p>
      <p>{link}</p>
      <Button onClick={handleCopyLink}>Copy Link</Button>
      <Button onClick={onClose}>Close</Button>
    </PopupContainer>
  );
};

export default Popup;