// Import React and the NameScreen component
import React from 'react';
import Providers from './navigation';

// Define a new functional component
const App = () => {
  return (
    <div>
      <Providers />
    </div>
  );
};

// Export the component
export default App;
