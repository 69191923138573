import React, { useEffect, useState } from "react";
import { getDocs, getDoc, collection, deleteDoc, doc, query, where, updateDoc, orderBy } from "firebase/firestore";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import styled from 'styled-components';
import editIcon from '../assets/edit-icon.svg';
import deleteIcon from '../assets/delete-icon.svg';
import shareIcon from '../assets/share-icon.svg';
import Popup from '../components/Popup';

// Styled components
const GamesContainer = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 100px;
`;

const Title = styled.h1`
  margin: 0;
`;

const SearchBar = styled.input`
  padding: 10px;
  font-size: 16px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border: none;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;

const Card = styled.div`
  width: calc(20% - 30px); /* Adjust the width minus gap for 4 cards per row */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: var(--white);
  border-radius: 10px;
`;

const GameImage = styled.img`
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const GameTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 32px;
  flex-grow: 2;
  color: white;
  gap: 10px;
  border: none;
`;

const IconButton = styled.button`
  border: none;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-size: cover;
  margin: 0 5px; // Spacing between icons
  background-color: transparent;
  flex-grow: 1; 

  &.edit-title {
    background-image: url(${editIcon});
  }

  &.share-game {
    background-image: url(${shareIcon});
  }

  &.delete-game {
    background-image: url(${deleteIcon});
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto; // Pushes the row to the bottom of the card if the card uses flex
`;

const GameCard = ({ game, loadStim, handleChangeName, handleChangeImage, deleteStim }) => {
  const imageUrl = game.imageUrl || process.env.REACT_APP_API_URL + '/logo192.png';
  const [showPopup, setShowPopup] = useState(false);
  const shareLink = `${process.env.REACT_APP_API_URL}/create?id=${game.id}`; // Construct the game's shareable link

  const handleShareGame = () => {
    setShowPopup(true); // Show the popup
  };

  return (
    <Card>
      <GameImage onClick={() => handleChangeImage(game.id)} src={imageUrl} alt={game.name} />
      <GameTitle>{game.name}</GameTitle>
      <ButtonRow>
        <Button onClick={() => loadStim(game.id)} >LOAD GAME</Button>
        <IconButton className="edit-title" onClick={() => handleChangeName(game.id)} />
        <IconButton className="share-game" onClick={handleShareGame} />
        <IconButton className="delete-game" onClick={() => deleteStim(game.id)} />
      </ButtonRow>
      {showPopup && <Popup link={shareLink} onClose={() => setShowPopup(false)} />}
    </Card>
  );
};

function MyEyedeas() {
  const [stimsList, setStimsList] = useState([]);
  const stimsCollectionRef = collection(db, "stims");
  const [showSettings, setShowSettings] = useState(null);

  const toggleSettings = (stimId) => {
    if (showSettings === stimId) {
      setShowSettings(null);
    } else {
      setShowSettings(stimId);
    }
  };

  const handleChangeName = async (stimId) => {
    const newName = prompt("Enter new name:");
  
    if (newName) {
      // Reference to the specific stim document
      const stimDocRef = doc(db, "stims", stimId);
  
      // Update the name field in the document
      await updateDoc(stimDocRef, {
        name: newName
      });
  
      // Update the state to reflect the change in the UI
      setStimsList(stimsList.map(stim => {
        if (stim.id === stimId) {
          return { ...stim, name: newName };
        }
        return stim;
      }));
    } else {
      console.log("Name change was cancelled");
    }
  };

  const handleChangeImage = async (stimId) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        // Get the existing image URL from Firestore
        const stimDocRef = doc(db, 'stims', stimId);
        const stimDoc = await getDoc(stimDocRef);
        const existingImageUrl = stimDoc.data().imageUrl;
  
        // Create an image element
        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
  
        img.onload = async () => {
          // Create a canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Set canvas dimensions
          canvas.width = 100;
          canvas.height = 100;

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, 100, 100);
  
          canvas.toBlob(async (blob) => {
            console.log('Blob created:', blob); // Check the blob
          
            const uniqueFileName = `images/${Date.now()}-${file.name}`; // Unique file name
            const storageRef = ref(storage, uniqueFileName);
          
            try {
              const snapshot = await uploadBytes(storageRef, blob);
              const downloadURL = await getDownloadURL(snapshot.ref);
              
              console.log('Download URL:', downloadURL); // Check the download URL
              // Update Firestore with the new image URL
              await updateDoc(stimDocRef, { imageUrl: downloadURL });
  
              // Update local state
              setStimsList(stimsList.map(stim => {
                if (stim.id === stimId) {
                  return { ...stim, imageUrl: downloadURL };
                }
                return stim;
              }));
  
              // Delete the old image from Firebase Storage
              if (existingImageUrl) {
                const oldImageRef = ref(storage, existingImageUrl);
                deleteObject(oldImageRef).catch(error => {
                  console.error('Error deleting old image: ', error);
                });
              }
            } catch (error) {
              console.error('Error uploading new image: ', error);
            }
          }, 'image/jpeg');
        };
      }
    };
    fileInput.click();
  };
  

  const deleteStim = async (id) => {
    // Display a confirmation dialog before deleting
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      const postDoc = doc(db, "stims", id);
      await deleteDoc(postDoc);
      // Optionally, update the UI or state after deletion
      setStimsList(stimsList.filter(stim => stim.id !== id));
    } else {
      console.log("Deletion cancelled");
    }
  };

  const loadStim = (stimid) => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/create?id=${stimid}`;
    } catch (error) {
      console.error("Error loading stim ", error);
    }
  };

  useEffect(() => {
    const getStims = async () => {
      if (auth.currentUser) {

        let fetchedStims = [];

        // get dino game stim
        const specificDocRef = doc(db, 'stims', '2Hcufvs9ykcaG1MXzsIF');
        const docSnap = await getDoc(specificDocRef);
        if (docSnap.exists()) {
          fetchedStims.push({ id: docSnap.id, ...docSnap.data() });
        }

        // get all stims by this author
        const userId = auth.currentUser.uid;
        const q = query(
          stimsCollectionRef,
          where("author.id", "==", userId),
          orderBy("updatedAt", "desc") // Order by updatedAt in descending order
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedStims.push({ ...data, id: doc.id, name: data.name || "Untitled" });
        });

        setStimsList(fetchedStims);

        // setStimsList(querySnapshot.docs.map((doc) => {
        //   const data = doc.data();
        //   return { ...data, id: doc.id, name: data.name || "Untitled" };
        // }));
      } else {
        console.log("No user logged in");
        setStimsList([]);
      }
    };

    getStims();
  }, [auth.currentUser]);

  // Inline styles for the icons
  const iconStyle = {
    fontSize: '20px', // Adjust the size as needed
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  };

  return (
    // <div className="homePage">
    //   {stimsList.map((stim) => (
    //     <div className="post" key={stim.id}>
    //       <div className="postHeader">
    //         <div className="title" style={{ width: '100%', textAlign: 'left' }}>
    //           <h1>{stim.name}</h1>
    //         </div>
    //         <div className="postImage" style={{ width: '100%', textAlign: 'left' }}>
    //           {stim.imageUrl && <img src={stim.imageUrl} alt={stim.name} style={{ maxWidth: '100%', height: 'auto' }} />}
    //         </div>
    //         <div className="postControls">
    //           <button style={iconStyle} onClick={() => handleChangeName(stim.id)}>&#9998;</button>
    //           <button style={iconStyle} onClick={() => handleChangeImage(stim.id)}>&#128247;</button>
    //           <button style={iconStyle} onClick={() => deleteStim(stim.id)}>&#128465;</button>
    //         </div>
    //       </div>
    //       <div className="postTextContainer">{stim.id}</div>
    //       <button onClick={() => loadStim(stim.id)}>Load Stim</button>
    //     </div>
    //   ))}
      <GamesContainer>
      <Header>
        <Title>My Eyedeas</Title>
        <SearchBar placeholder="Search games..." />
      </Header>
      <CardsContainer>
        {stimsList.map((stim) => (
          <GameCard
            key={stim.id}
            game={stim}
            loadStim={loadStim}
            handleChangeName={handleChangeName}
            handleChangeImage={handleChangeImage}
            deleteStim={deleteStim}
          />
        ))}
      </CardsContainer>
    </GamesContainer>
  );  
}

export default MyEyedeas;
