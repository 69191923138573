import React, { useState, useContext } from 'react';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import Dashboard from '../screens/Dashboard';
import CreateEyedea from '../screens/CreateEyedea';
import MyEyedeas from '../screens/MyEyedeas';
import Tutorials from '../screens/Tutorials';
import Login from '../screens/LoginScreen';
import Signup from '../screens/SignupScreen';
import AboutUs from '../screens/AboutUs';
import ContactUs from '../screens/ContactUs';
import Footer from '../components/Footer';
import styled from 'styled-components';
import logo from '../assets/logo192.png';
import "../Background.css";

// Styled components
// rounded corners
const NavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
  background-color: var(--white);
  border-radius: 25px;
  width: calc(100vw - 40px); /* Subtracting horizontal margins from the viewport width */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
`;


const NavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 0 20px;

  img {
    width: 5vw; /* or any other relative size */
    height: auto; /* to maintain aspect ratio */
  }
`;

const NavCenter = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 4;
  padding: 0 20px;
`;

const NavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 20px;
`;



const StyledLink = styled(Link)`
  color: var(--black);
  margin-right: 20px;
  text-decoration: none;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoutButton = styled.button`
  background-color: var(--blue);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #6c9448;
  }
`;

// Rest of your component code




const AppStack = () => {
    const { logout, user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = async (e) => {
        e.preventDefault();
        await logout();
        navigate('/auth/login');
    };
    return (
      <div className="container">
        <div className="background-container">
          <div className="overlay"></div>
        </div>
        <div className="content">
        <NavBar>
          <NavLeft>
            {/* <img src={logo} alt="eyedeate logo" /> */}
            {/* <img src={process.env.REACT_APP_API_URL + '/logo.svg'} alt="Logo" /> */}
            <img src={logo} alt="Eyedeate Logo" style={{height: "70%"}} /> 
          </NavLeft>
          <NavCenter>
            <StyledLink to="/">Home</StyledLink>
            <StyledLink to="/createeyedea">Create Eyedea</StyledLink>
            {user && <StyledLink to="/myeyedeas">My Eyedeas</StyledLink>}
            <StyledLink to="/tutorials">Tutorials</StyledLink>
            <StyledLink to="/aboutus">About Us</StyledLink>
            <StyledLink to="/contactus">Contact Us</StyledLink>
          </NavCenter>
          <NavRight>
            {!user ? (
              <>
                <StyledLink to="/auth/login">Login</StyledLink>
                <StyledLink to="/auth/signup">Sign Up</StyledLink>
              </>
            ) : (
              // Using StyledLink for logout to match the style of Login/Sign Up
              <StyledLink to="/" onClick={handleLogout}>Log Out</StyledLink>
            )}
          </NavRight>
        </NavBar>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/myeyedeas" element={<MyEyedeas />} />
          <Route path="/createeyedea" element={<CreateEyedea />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
      </div>
    );
    
};

export default AppStack;
