import React from "react";
import Routes from "./Routes";
import { AuthProvider } from "./AuthProvider";
import { ThemeProvider } from 'styled-components';


const theme = {
    colors: {
        primary: '#71B0EA',
        primary_variant: '#2e64e5',
        secondary: '#03F4E1',
        secondary_variant: '#67CAC7',
    },
};

const Providers = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Routes />
            </AuthProvider>
        </ThemeProvider>
    );
};

export default Providers;
