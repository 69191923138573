import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import NameScreen from '../screens/NameScreen';
import LoginScreen from '../screens/LoginScreen';
import SignupScreen from '../screens/SignupScreen'; 
import TestScreen from '../screens/TestScreen';

function AuthStack() {
    return (
        <Routes>
            <Route path="/name" element={<NameScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/signup" element={<SignupScreen />} />
            <Route path="/test" element={<TestScreen />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
    );
}

export default AuthStack;
