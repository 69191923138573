import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [form, setForm] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const messageMaxLength = 2000;

  const sendEmail = (e) => {
    e.preventDefault();

    if (form.message.length > messageMaxLength) {
      alert('Message is too long. Please reduce the length and try again.');
      return;
    }

    emailjs.send('service_vw0dhaa', 'template_8v2o2bv', form, 'bz88vqD3wMQzO8MAN')
      .then((result) => {
          console.log(result.text);
          // Clear form or show success message
      }, (error) => {
          console.log(error.text);
          // Show error message
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'message' && value.length > messageMaxLength) {
      return; // Prevents typing more characters once limit is reached
    }
    setForm({ ...form, [name]: value });
  };

  const containerStyle = {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '20px 40px', // Increased side padding
    borderRadius: '10px',
    width: '450px',
    margin: '40px auto', // Increased top margin
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' // Optional: added box-shadow for depth
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const inputStyle = {
    marginBottom: '10px',
    padding: '15px',
    fontSize: '18px',
    width: '100%',
  };

  const textareaStyle = {
    height: '200px',
    marginBottom: '10px',
    padding: '15px',
    fontSize: '18px',
    width: '100%',
  };


  const counterStyle = {
    textAlign: 'right',
    fontSize: '12px',
    width: '100%',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h1>Contact Us</h1>
      <p>Please feel free to reach out to us!</p>
      <form onSubmit={sendEmail} style={formStyle}>
        <input 
          type="text" 
          name="user_name" 
          onChange={handleChange} 
          value={form.user_name} 
          placeholder="Your Name" 
          style={inputStyle} 
        />
        <input 
          type="email" 
          name="user_email" 
          onChange={handleChange} 
          value={form.user_email} 
          placeholder="Your Email" 
          style={inputStyle} 
        />
        <textarea 
          name="message" 
          onChange={handleChange} 
          value={form.message} 
          placeholder="Your Message" 
          style={textareaStyle} 
          maxLength={messageMaxLength}
        />
        <div style={counterStyle}>
          {form.message.length} / {messageMaxLength}
        </div>
        <input 
          type="submit" 
          value="Send" 
          style={inputStyle} 
        />
      </form>
    </div>
  );
};

export default ContactUs;
