import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../navigation/AuthProvider';
import { useNavigate, Navigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;


const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px; // Significantly increased width
  background-color: white;
  padding: 40px; // Substantially increased padding
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 20px; // Increased margin for better spacing
  padding: 20px; // Significantly increased padding
  font-size: 1.3rem; // Larger font size for better readability
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #7daa57; // Slightly darker green
  color: white;
  padding: 20px;
  font-size: 1.3rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Adjusted margin

  &:hover {
    background-color: #6c9448; // Even darker for the hover state
  }
`;

const GoogleSignInButton = styled(Button)`
  background-color: #DB4437;
  &:hover {
    background-color: #C1351D;
  }
`;

const SignUpPrompt = styled.p`
  margin-top: 20px;
  color: #333;
  font-size: 1rem;
`;

const SignUpLink = styled.span`
  color: #8fbc6a; // Light green color
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;


const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { emailLogin, googleSignIn } = useContext(AuthContext);
    const [navigateToSignup, setNavigateToSignup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await emailLogin(email, password);
        navigate("/")
    };

    const handleNavigateToSignup = () => {
        setNavigateToSignup(true);
        navigate("/auth/signup")
    };

    const handleGoogleSignIn = async () => {
        await googleSignIn();
        navigate("/")
    };

    if (navigateToSignup) {
        return <Navigate to="/auth/signup" />;
    }
    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Input 
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input 
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit">Login</Button>
                {/* <Button onClick={handleNavigateToSignup}>Sign Up</Button> */}
            <GoogleSignInButton onClick={handleGoogleSignIn}>Sign in with Google</GoogleSignInButton>
            <SignUpPrompt>
              Don't have an account? <SignUpLink onClick={handleNavigateToSignup}>Sign up here</SignUpLink>
            </SignUpPrompt>
            </Form>
        </Container>
    );
};

export default LoginScreen;
