import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../navigation/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px; // Adjusted width
  background-color: white;
  padding: 40px; // Adjusted padding
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.3rem; // Adjusted font size
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #7daa57; // Slightly darker green
  color: white;
  padding: 20px;
  font-size: 1.3rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Adjusted margin

  &:hover {
    background-color: #6c9448; // Even darker for the hover state
  }
`;

const SignupScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { register } = useContext(AuthContext);
    const navigate = useNavigate(); // Use the useNavigate hook

    const handleSignUp = async (e) => {
        console.log("woihfioeh");
        e.preventDefault();
        if (password !== confirmPassword) {
            console.error("Passwords do not match!");
            return;
        }
        try {
            await register(email, password);
            // Handle post-registration logic
        } catch (error) {
            console.error(error);
            // Handle errors
        }
    };

    // Function to navigate back to the login screen
    const navigateToLogin = () => {
        navigate('/auth/login'); // Update this path according to your route configuration
    };

    return (
        <Container>
            <Form onSubmit={handleSignUp}>
                <Input 
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input 
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Input 
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button type="submit">Sign Up</Button>
                <Button type="button" onClick={navigateToLogin} style={{ backgroundColor: '#71B0EA' }}>Back to Login</Button>
            </Form>
        </Container>
    );
};

export default SignupScreen;