import React from 'react';
// import { motion } from 'framer-motion';
import visualRuleGif from '../visual-rule.gif'; // Replace with the correct path to your GIF
import { useNavigate } from 'react-router-dom';

// const floatVariants = {
//     animate: {
//       y: ["-5%", "5%"],
//       transition: {
//         duration: 2.5,
//         yoyo: Infinity,
//         ease: "easeInOut"
//       }
//     }
//   };
  

// const Dashboard = () => {
//   return (
//     <div className="dashboard-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
//     <motion.h1
//         style={{ fontSize: '4rem', marginBottom: '20px' }}
//         variants={floatVariants}
//         initial="start"
//         animate="animate"
//     >
//         Welcome to <p style={{color: 'var(--blue)'}}>Eyedeate!</p>
//     </motion.h1>

//         <h2 style={{ fontSize: '2rem', fontFamily: 'Arial, sans-serif' }}>Using Visual Rules to Create</h2>
//         <img src={visualRuleGif} alt="Visual Rule" style={{ maxWidth: '80%', height: 'auto' }} />
//     </div>
//   );
// };

// export default Dashboard;

// Dashboard.js
import styled, { keyframes } from 'styled-components';

// Keyframes for animations
const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const clickAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
`;

// Styled components
const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

const SectionContainer = styled.div`
  margin: 40px 0;
`;

const Title = styled.h1`
  animation: ${floatAnimation} 5s ease-in-out infinite;
  font-size: 4rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  margin: 20px 0;
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  margin-right: 20px;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 32px;
  color: white;
  gap: 10px;
  border: none;
  &:hover {
    animation: ${clickAnimation} 0.5s ease;
  }
`;

const StartButton = styled(Button)`
  background-color: #4CAF50; /* Green */
  color: white;
`;

// Dashboard Component
const Dashboard = () => {
  const navigate = useNavigate(); // Use the useNavigate hook

  // Function to navigate to the login screen
  const navigateToLogin = () => {
    navigate('/auth/login'); // Update this path according to your route configuration
  };

  // Function to navigate to create account
  const navigateToCreateAccount = () => {
    navigate('/auth/signup'); // Update this path according to your route configuration
  };

  // Function to navigate to create game
  const navigateToCreateGame = () => {
    navigate('/createeyedea'); // Update this path according to your route configuration
  };

  return (
    <Container>
      <Title>Welcome to <span style={{color: "var(--blue)"}}>Eyedeate</span></Title>

      <p>Eyedeate is a simple, fun, and intuitive way to code! Build games and simulations using just visual rules.</p>
        <p style={{color: "var(--blue)"}}><i> Get started with these 3 steps!</i></p>
      <SectionContainer>
        <h3>Step 1: Create an account or login</h3>
        <p></p>
        <Button onClick={navigateToCreateAccount}>Create Account</Button>
        <Button onClick={navigateToLogin}>Login</Button>
      </SectionContainer>
      <SectionContainer>
        <h3>Step 2: Create your game with visual rules</h3>
        {/* Replace the src attribute with the path to your gif */}
        <img src={visualRuleGif} alt="Visual Rules" />
      </SectionContainer>
      <SectionContainer>
        <h3>Step 3: Play and share your game with friends!</h3>
        <StartButton onClick={navigateToCreateGame}>Create Eyedeas!</StartButton>
      </SectionContainer>
    </Container>
  );
};

export default Dashboard;


