import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase-config";
import { addDoc, collection } from "firebase/firestore";

const baseURL = process.env.REACT_APP_API_URL;

const CreateEyedea = () => {
  const [stimId, setStimId] = useState(null);

  useEffect(() => {
    const createNewStim = async () => {
      try {
        const docRef = await addDoc(collection(db, "stims"), {
          // Initial stim data (if any)
          new: true,
          createdAt: new Date(),
          author: {
            name: auth.currentUser ? auth.currentUser.displayName : "Anonymous",
            id: auth.currentUser ? auth.currentUser.uid : "N/A",
          },
        });
        setStimId(docRef.id); // Save the generated ID
        // Redirect or perform other actions as needed
        // /redirect-to-create/:id
        // const response = await fetch("redirect-to-create?id=" + docRef.id, {
        //     method: "GET"
        // });
        //   (`${baseURL}/create?id=${docRef.id}`, {
        //   method: "GET"
        // });
        // if (response.ok) {
        //   console.log("response ok");
        // }
        // else {
        //   console.log("response not ok");
        // }

        console.log("link is", baseURL + "/create?id=" + docRef.id);
        console.log("before: " + window.location.href);
        window.location.href = `${process.env.REACT_APP_API_URL}/create?id=${docRef.id}`;
        console.log("after: " + window.location.href);
      } catch (error) {
        console.error("Error creating new sketch: ", error);
      }
    };

    createNewStim();

    // window.location.href = `${process.env.REACT_APP_API_URL}/create`;
  }, []); // Empty dependency array means this runs on component mount

  return <div>Redirecting... {stimId && <p>Sketch ID: {stimId}</p>}</div>;
};

export default CreateEyedea;
