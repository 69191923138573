import React, { createContext, useState, useEffect } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import { auth, db } from "../firebase-config";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  console.log("AuthProvider the second");
  const [user, setUser] = useState(null);

  // Function for Google Sign-In
  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      // The signed-in user info.
      await serverAuthenticate(result);
    } catch (error) {
      console.log(error);
    }
  };

  const serverAuthenticate = async (result) => {
    // The signed-in user info.
    const user = result.user;
    console.log("User signed in:", user);

    // Get the ID token from the Firebase user
    const token = await user.getIdToken(true);
    console.log("Token is " + token);
    // Send the token to your server for verification
    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Include the token here
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    });

    // Handle the server response
    if (response.ok) {
      setUser(user); // Update the user state
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // If the user document does not exist, create a new one
        await setDoc(userDocRef, {
          uid: user.uid,
          email: user.email,
          name: user.displayName,
          userImg: user.photoURL,
          createdAt: Timestamp.fromDate(new Date()),
          // Set other fields as needed
        });
        console.log("User document created in Firestore");
      } else {
        console.log("User already exists in Firestore");
      }
      // If successful, you might redirect to the game engine page
      console.log("Token verified by server");
      // Redirect logic here, for example:
      console.log("navigating");
      // window.location.href = "${process.env.REACT_APP_API_URL}/create";
      console.log("navigating");
      // navigate("/create");
      //   await fetch ("${process.env.REACT_APP_API_URL}/create", {
      //     method: "GET",
      // });
      console.log("navigated");
    } else {
      console.error("Server rejected token");
    }
  };

  // function for email login
  const emailLogin = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await serverAuthenticate(result);
    } catch (e) {
      console.log(e);
    }
  };

  const register = async (email, password) => {
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = response.user.uid;
      await setDoc(doc(db, "users", uid), {
        uid: uid,
        name: "",
        email: email,
        createdAt: Timestamp.fromDate(new Date()),
        userImg: null,
        // Set other fields as needed
      });
      // todo: Handle post-registration (e.g., redirecting to a login page or a profile page)
    } catch (e) {
      console.log("something went wrong with sign up: ", e);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (e) {
      console.log(e);
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Handle the server response
    if (response.ok) {
      console.log("Token verified by server");
      setUser(null); // Update the user state
    } else {
      console.error("Server rejected token");
    }
  };

  // Subscribe to Firebase auth state change
  // useEffect(() => {
  //     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
  //     setCurrentUser(user);
  //     setLoading(false);
  //     });

  //     return () => {
  //     unsubscribe();
  //     };
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        emailLogin,
        register,
        logout,
        googleSignIn, // Expose the Google Sign-In function
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
