import React, { useEffect, useState } from "react";
import { getDocs, getDoc, collection, deleteDoc, doc, query, where, updateDoc, orderBy } from "firebase/firestore";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import instructionManualImage from "../beginnersguide.png"; // Replace with the correct path to your image
import styled from 'styled-components';
import editIcon from '../assets/edit-icon.svg';
import deleteIcon from '../assets/delete-icon.svg';
import shareIcon from '../assets/share-icon.svg';
import Popup from '../components/Popup';

// Styled components
const GamesContainer = styled.div`
  padding: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 100px;
`;

const Title = styled.h1`
  margin: 0;
`;

const SearchBar = styled.input`
  padding: 10px;
  font-size: 16px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border: none;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;

const Card = styled.div`
  width: calc(20% - 30px); /* Adjust the width minus gap for 4 cards per row */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: var(--white);
  border-radius: 10px;
`;

const GameImage = styled.img`
  width: 80%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const GameTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 20px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 32px;
  flex-grow: 2;
  color: white;
  gap: 10px;
  border: none;
`;

const IconButton = styled.button`
  border: none;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-size: cover;
  margin: 0 5px; // Spacing between icons
  background-color: transparent;
  flex-grow: 1; 

  &.edit-title {
    background-image: url(${editIcon});
  }

  &.share-game {
    background-image: url(${shareIcon});
  }

  &.delete-game {
    background-image: url(${deleteIcon});
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto; // Pushes the row to the bottom of the card if the card uses flex
`;
const GameCard = ({ game, loadStim, handleChangeName, handleChangeImage, deleteStim }) => {
  const imageUrl = game.imageUrl || process.env.REACT_APP_API_URL + '/logo192.png';
  const [showPopup, setShowPopup] = useState(false);
  const shareLink = `${process.env.REACT_APP_API_URL}/create?id=${game.id}`; // Construct the game's shareable link

  const handleShareGame = () => {
    setShowPopup(true); // Show the popup
  };

  return (
    <Card>
      {/* <GameImage onClick={() => handleChangeImage(game.id)} src={imageUrl} alt={game.name} /> */}
      <GameImage src={imageUrl} alt={game.name} />
      <GameTitle>{game.name}</GameTitle>
      <ButtonRow>
        <Button onClick={() => loadStim(game.id)} >LOAD GAME</Button>
        {/* <IconButton className="edit-title" onClick={() => handleChangeName(game.id)} /> */}
        <IconButton className="share-game" onClick={handleShareGame} />
        {/* <IconButton className="delete-game" onClick={() => deleteStim(game.id)} /> */}
      </ButtonRow>
      {showPopup && <Popup link={shareLink} onClose={() => setShowPopup(false)} />}
    </Card>
  );
};

function Tutorials() {
  const fileUrl = `${process.env.REACT_APP_API_URL}/BeginnersGuide.pdf`;
  const [stimsList, setStimsList] = useState([]);
  const stimsCollectionRef = collection(db, "stims");
  const [showSettings, setShowSettings] = useState(null);

  const toggleSettings = (stimId) => {
    if (showSettings === stimId) {
      setShowSettings(null);
    } else {
      setShowSettings(stimId);
    }
  };

  const loadStim = (stimid) => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/create?id=${stimid}`;
    } catch (error) {
      console.error("Error loading stim ", error);
    }
  };

  useEffect(() => {
    const getStims = async () => {
      if (auth.currentUser) {

        let fetchedStims = [];

        // get dino game stim
        // const specificDocRef = doc(db, 'stims', '2Hcufvs9ykcaG1MXzsIF');
        // const docSnap = await getDoc(specificDocRef);
        // if (docSnap.exists()) {
        //   fetchedStims.push({ id: docSnap.id, ...docSnap.data() });
        // }

        // fetch all tutorials
        const q = query(
          stimsCollectionRef,
          where("tutorial", "==", true),
          orderBy("updatedAt", "desc") // Order by updatedAt in descending order
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedStims.push({ ...data, id: doc.id, name: data.name || "Untitled" });
        });

        setStimsList(fetchedStims);

      } else {
        console.log("No user logged in");
        setStimsList([]);
      }
    };

    getStims();
  }, [auth.currentUser]);


  return (
    // <div className="homePage">
    //   {/* <h2 style={{ fontSize: '2rem', fontFamily: 'Arial, sans-serif' }}>Getting Started</h2> */}
    //   {/* <p>View the image below to see the instruction manual and basic controls. More interactive tutorials coming soon...</p> */}
    //   {/* <img src={instructionManualImage} alt="Instruction Manual" style={{ maxWidth: '80%', height: 'auto' }} /> */}
    //   {stimsList.map((stim) => (
    //     <div className="post" key={stim.id}>
    //       <div className="postHeader">
    //         <div className="title" style={{ width: '100%', textAlign: 'left' }}>
    //           <h1>{stim.name}</h1>
    //         </div>
    //         <div className="postImage" style={{ width: '100%', textAlign: 'left' }}>
    //           {stim.imageUrl && <img src={stim.imageUrl} alt={stim.name} style={{ maxWidth: '100%', height: 'auto' }} />}
    //         </div>
    //       </div>
    //       <div className="postTextContainer">{stim.id}</div>
    //       <button onClick={() => loadStim(stim.id)}>Load Stim</button>
    //     </div>
    //   ))}
    // </div>
    <GamesContainer>
    <Header>
      <Title>Try These Games!</Title>
      <SearchBar placeholder="Search games..." />
    </Header>
    <CardsContainer>
      {stimsList.map((stim) => (
        <GameCard
          key={stim.id}
          game={stim}
          loadStim={loadStim}
        />
      ))}
    </CardsContainer>
    <Title>
      Beginner's Guide to Controls
    </Title>
    <p>View the image below to see the instruction manual and basic controls. More interactive tutorials coming soon...</p>
    <img src={instructionManualImage} alt="Instruction Manual" style={{ maxWidth: '70%', height: 'auto' }} />
    {/* <iframe
          src={fileUrl}
          style={{ width: '70%', height: '1024px' }} // Adjust the height as needed
          frameBorder="0"
        >
            This browser does not support PDFs. Please download the PDF to view it: <a href={fileUrl}>Download PDF</a>
    </iframe> */}
  </GamesContainer>
  );  
}

export default Tutorials;
