import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: var(--secondary-color);
  margin-right: 20px;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterContainer = styled.footer`
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px 0; // Adjust padding as needed
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); // Optional shadow for depth
`;

const Footer = () => {
    return (
      <FooterContainer>
        <StyledLink href={`${process.env.REACT_APP_API_URL}/tos.pdf`} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </StyledLink>
        <StyledLink href={`${process.env.REACT_APP_API_URL}/privacy-policy.pdf`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </StyledLink>
      </FooterContainer>
    );
  };
  
export default Footer;